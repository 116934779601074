<template>
  <!--
  list - объект, список элементов, должен содержать обязательный ключ title. Остальные ключи могут быть любые
  title - название элемента списка
  placeholder - необязательный, обычный placeholder
  castom - это кастомный элемент, для фильтрации. Например когда в фильтре помимо занчений из списка нужно показать Все. необязательный
  -->
  <div tabindex="1" @blur="CloseDropDown" class="custom-select">
    <div @click="ChangeStatusCustomSelect" class="custom-select__header">

      <p v-if="titleIn" v-trim="1">{{ titleIn }}</p>
      <p v-else v-trim="1">{{ placeholder ? placeholder : titleIn }}</p>

      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="11.1549" y="5.05664" width="7.00006" height="2.00002" rx="1" transform="rotate(134.83 11.1549 5.05664)" fill="#5A79A8"/>
        <rect x="2.66565" y="3.66797" width="7.00006" height="2.00002" rx="1" transform="rotate(44.8295 2.66565 3.66797)" fill="#5A79A8"/>
      </svg>

    </div>
    <transition name="fade">
      <div v-show="statusCustomSelect" class="custom-select__main">
        <p v-if="castom" @click="changeTitle(castom)">
          <input type="checkbox" v-if="multiple" :checked="!selectedList.length">
          {{ castom }}
        </p>

        <p v-for="(item,i) in list"
           :key="`CustomSelect${i}`"
           @click="changeTitle(item.title, i)">
          <input type="checkbox" v-if="multiple" :checked="selectedList.includes(i)">
          {{ item.title }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'CustomSelect',
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    list: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String
    },
    castom: {
      type: String
    },
    default: {
      type: Number
    },
    // title - передаем текст который нужно вывести заголовке в селект
    title: {
      required: false,
      default: 'Выберите'
    },
    // key_name - передается в паре с title, это ключ тайтла. например {name: 'заголовок в селекте'}
    // То в title передаем 'заголовок в селекте', а в key_name передаем name
    // Это для того чтобы в полученном массиве сразу по фильтру найти строку с тайтлом и вернуть этот объект куда нужно уже
    // если запись по тайтлу в массие не найдена то вернется []
    key_name: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      statusCustomSelect: false,
      titleIn: '',
      selectedList: []
    }
  },

  watch: {
    title (event) {
      this.titleIn = event
      if (this.title && this.key_name) {
        const value = this.list.filter(el => {
          return String(el[`${this.key_name}`]) === this.titleIn
        })
        this.$emit('click', value) // ну или что там нужно..
      }
    },
    list () {
      if (this.default !== undefined) {
        this.titleIn = this.list[this.default].title
      }
    }
  },
  methods: {
    ChangeStatusCustomSelect () {
      this.statusCustomSelect = !this.statusCustomSelect
    },
    CloseDropDown () {
      this.statusCustomSelect = false
    },
    changeTitle (value, key) {
      if (this.multiple && key !== undefined) {
        if (this.selectedList.includes(key)) {
          const elementIndex = this.selectedList.findIndex((element) => {
            return element === key
          })
          this.selectedList.splice(elementIndex, 1)
        } else {
          this.selectedList.push(key)
        }
        this.$emit('InputsContent', this.getSelectedElements())

        if (this.selectedList.length) {
          this.titleIn = 'Выбрано ' + this.selectedList.length
        } else {
          this.titleIn = this.placeholder
        }
      } else if (key !== undefined) {
        this.CloseDropDown()
        this.$emit('InputsContent', key !== undefined ? this.list[key] : '')
        this.titleIn = value
      } else {
        this.titleIn = this.placeholder
        this.selectedList = []
        this.$emit('InputsContent', '')
      }
    },
    getSelectedElements () {
      return this.list.filter((element, index) => this.selectedList.includes(index))
    }
  }
}
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  text-align: left;
  width: 19.375rem;
  background: rgba(238, 244, 250, 0.6);
  border-radius: 3px;

  &__header {
    font-size: 0.875rem;
    line-height: 157%;
    color: rgba(0, 62, 120, 0.6);
    padding: 0.56rem 1.25rem;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    svg {
      min-width: 1rem;
      min-height: 1rem;
    }
  }

  &__main {
    position: absolute;
    top: 140%;
    z-index: 99999;
    background: #fff;
    box-sizing: border-box;
    width: 20.375rem;
    text-align: left;
    padding: 1.5rem 0.875rem;

    filter: drop-shadow(0px 33px 88px rgba(37, 70, 103, 0.15));

    p {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      line-height: 157%;
      color: #003E78;
      padding: 0.875rem;

      cursor: pointer;

      > input {
        margin-right: .625rem;
      }
    }
    p:hover {
      background: rgba(36, 108, 183, 0.1);
      border-radius: 3px;
    }
  }
}
.fade-enter-active, .component-fade-leave-active {
  transition: all .35s;
}
.fade-enter, .component-fade-leave-to{
  opacity: 0;
  transform: translateY(-1rem);
}

@media screen and (max-width: 768px){
  .custom-select__header{
    padding: 0.4rem 1.25rem;
  }
}
</style>
