<template>
  <div class="container">
    <a class="come-back" @click.prevent="$router.go(-1)">
      <div></div>
      Вернуться назад</a>
    <div class="white-container">
      <form @submit.prevent="createPost" ref="createPostIt">

        <div>
          <div class="content-sb">
            <p>Рубрика</p>
            <custom-select :title="dataForm.rubric.title" :key="`inclusion1`" class="select" :list='list'
                           @InputsContent="setInclusion" placeholder="Выберите рубрику"/>
          </div>

          <div class="content-sb">
            <p>Теги</p>
<!--            <tags @dataTags="dataTags" :startValue="dataForm.tagList"/>-->
            <tags @dataTags="dataTags"/>
          </div>

          <div class="content-sb">
            <p>Заголовок</p>
            <input required="required" v-model="dataForm.title" placeholder="Введите заголовок" type="text" name="name"
                   class="input blue">
          </div>

          <div class="content-sb">
            <p>Текст публикации</p>
            <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="dataForm.text"></ckeditor>
          </div>
        </div>

        <footer class="content-sb btns-create-form">
          <a @click.prevent="$router.go(-1)" class="btn-white">Отмена</a>
<!--          <router-link :to="`/blog?idUser=${idUser}`" class="btn-white">Отмена</router-link>-->
          <input type="submit" class="btn-blue" value="Сохранить"/>
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/forms/CustomSelect'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'CreateBlog',
  data () {
    return {
      idUser: '',
      dataForm: {
        title: '',
        text: '',
        rubric: '',
        tagList: []
      },
      list: [
        {
          id: 1,
          code: 'ACTUAL_COMMENT',
          title: 'Актуальный комментарий'
        }
      ]
    }
  },
  methods: {
    setInclusion (value) {
      // console.log(value.code)
      this.dataForm.rubric = value
    },
    dataTags (value) {
      // console.log(value)
      this.dataForm.tagList = value
    },
    createPost () {
      const formData = new FormData(this.$refs.createPostIt)
      formData.append('text', this.dataForm.text)
      formData.append('title', this.dataForm.title)
      formData.append('tagList', JSON.stringify(this.dataForm.tagList))
      // console.error(this.dataForm.tagList)
      formData.append('rubric', this.dataForm.rubric.code)
      if (this.$route.query.type !== 'edit') {
        this.$store.dispatch('apiAddPost', { data: formData, idMember: this.idUser })
      } else {
        this.$store.dispatch('apiAUpdatePost', { data: formData, idMember: this.idUser, idPost: this.$store.getters.GET_CURRENT_POST.id })
      }
    }
  },
  mounted () {
    this.idUser = this.$route.query.idUser
    if (this.$route.query.type === 'edit') {
      const currentPost = this.$store.getters.GET_CURRENT_POST
      // console.error(currentPost)
      this.idUser = this.$route.query.idUser
      this.dataForm.text = currentPost.text
      this.dataForm.title = currentPost.title
      this.dataForm.tagList.push(...currentPost.tagList)
      this.list.filter(item => {
        if (item.title === currentPost.rubric) {
          this.dataForm.rubric = item
        }
      })
    }
  },
  components: {
    CustomSelect,
    Tags
  }
}
</script>

<style scoped lang="scss">
  .white-container {
    width: 61.68rem;
    margin: 0 0 0 -2.5rem;
  }

  .select, .input {
    width: 33.75rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;
  }

  .wrapper-blog {
    margin-top: 1.25rem;
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }
</style>
